import { graphql } from 'gatsby'
import React from 'react'

import { RegistryPageQueryQuery } from '../../types/graphql-types'
// import { siteMetadata } from '../../gatsby-config'
import Layout from '../components/layout/layout'
import Meta from '../components/meta/meta'

interface Props {
  data: RegistryPageQueryQuery
  location: Location
}

const Registry: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout location={location}>
      <Meta site={meta} title="Registry" />
      <div>
        <section className="text-center">
          <div className="container">
            <h1>Coming Soon!</h1>
            <p className="lead text-muted"></p>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Registry

export const query = graphql`
  query RegistryPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        adsense
      }
    }
  }
`
